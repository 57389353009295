import axios from '@/utils/axios';
import {BASE_URL} from '@/utils/common'
import Qs from 'qs';

export function getCaseList (data){

    return axios({
        url: `${BASE_URL}case/page?${Qs.stringify({...data })}`,
        method: "get"
    })
}

export function getCaseDetail (data){
    return axios({
        url: `${BASE_URL}case/${data}`,
        method: "get"
    })
}

export function getSchoolIconList (data){
    return axios({
        url: `${BASE_URL}school-icon/list${Qs.stringify({...data })}`,
        method: "get"
    })
}
