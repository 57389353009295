<template>

  <section class="w-100p bg-light-grey">
    <div class="w-1200 h-600 overflow-hidden block-center flex-row flex-space-b">
      <div class="flex-col justify-center align-left col-b-title text-left">
        <div class="f-s-34 f-w-500">常态化</div>
        <div class="f-s-34 f-w-500">智慧教室引领者</div>
        <div class="f-s-16 f-w-500 m-t-19">5000+学校正在使用东信同邦智慧教室</div>
      </div>
      <div class="flex-row  flex-no-wrap  w-800 h-600 transformBox relative">
        <logo-banner :size="schoolIconTotal/3"  :transformHeight="230" isRotate class="m-l-20"></logo-banner>
        <logo-banner :page="2" :size="schoolIconTotal/3" :is-shadow="false" :speed=".7" :transformHeight="230" isRotate class="m-l-20"></logo-banner>
        <logo-banner :page="3" :size="schoolIconTotal/3" :speed=".9" :transformHeight="230" isRotate class="m-l-20"></logo-banner>
      </div>
    </div>
  </section>
  <div class="w-100p white-bg">
    <img src="@/assets/plan/mantle.png" alt="" class="h-60 w-100p">
  </div>
  <section class="white-bg w-100p p-b-120">
      <div class="w-1200 block-center text-center">
          <div class="col-b-title f-s-32 p-t-40  f-w-500">经典案例</div>
          <div class="flex-row  m-t-30 block-center classic  flex-space-b">
            <div class="classic-item pointer" :class="navIndex===index?'selected':''" v-for="(item,index) in navList" :key="index" @click="changeIndex(index)">{{item.name}}</div>
          </div>
          <div class="m-t-50 flex-row flex-wrap school-list" >
              <div class="brs4 pointer school-item  m-t-30" @click="checkDetail(item.id)" v-for="(item,index) in schoolList" :key="index">
                <div class="school-img">
                  <img :src="item.cover" alt="" >
                </div>
                <p class="l-h-50  f-s-16 f-w-500 col-b-title text-left p-l-10 wbba">{{item.title}}</p>
              </div>
          </div>
          <div class="f-s-14 col-b-blue p-t-40 pointer block-center" v-if="schoolList.length<total"
			   @click="checkMore()">查看更多 ></div>
      </div>
  </section>

</template>
<script>
import LogoBanner from "@/components/LogoBanner.vue";
import {defineComponent, onMounted, ref} from "vue";
import { useRouter } from 'vue-router'
import {getCaseList} from "../../api/project";
import { getSchoolIcon } from '@/api/webIndex'
export default defineComponent({
  components:{
    LogoBanner
  },
  setup(){
    onMounted(()=>{
      //getIconList();
      getSchoolList();
      onGetSchoolIcon()
    })
    const router = useRouter();
    //let  logoList = ref([]);
    let navIndex =ref(0);
    let page = ref(1);
    let schoolList = ref([]);
    let total =ref(0)
    let schoolIconTotal  = ref(0)
    const navList = [{name:'高教案例'},{name:'普教案例'}]
    function changeIndex(index){
      page.value = 1;
      total.value=0;
      navIndex.value = index;
      schoolList.value=[];
      getSchoolList();
    }
    function checkMore(){
      page.value +=1;
      getSchoolList();
    }
    async function getSchoolList(){
      try{
        const { error_code, data }  =await getCaseList({
          type:navIndex.value*1+1,
          per_page:15,
          page:page.value
        });
        if(error_code===0){
          const list = data.data;
          list.forEach(item=>{
            schoolList.value.push(item)
          })
          total.value = data.total;
        }else{
          schoolList.value = [];
        }
      }catch (e){
        schoolList.value = [];
      }
    }
    const logoList = [
      {
        id: 0,
        src: require('@/assets/dbsf.png')
      },
      {
        id: 1,
        src: require('@/assets/gxmz.png')
      },
      {
        id: 2,
        src: require('@/assets/gxnn.png')
      },
      {
        id: 3,
        src: require('@/assets/gxsf.png')
      },
      {
        id: 4,
        src: require('@/assets/gyxy.png')
      },
      {
        id: 5,
        src: require('@/assets/gjkf.png')
      },
    ]
    const onGetSchoolIcon = async () => {
      try {
        let res = await getSchoolIcon()
        if (res.message === 'ok') {
          schoolIconTotal.value = res.data.total
        } else {
          console.log(res.data.total)
        }
      } catch (e) {
        console.log(e)
      }
    }
    function checkDetail(id){
      router.push({
        name:'projectDetail',
        query:{
          id
        }
      })
    }
    return{
      logoList,
      navList,
      navIndex,
      changeIndex,
      checkDetail,
      schoolList,
      page,
      total,
      schoolIconTotal,
      checkMore,
      onGetSchoolIcon
    }
  }
})
</script>
<style lang="scss" scoped>
.transformBox ::before{
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 800px;
  height: 100px;
  background: linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%);
  z-index: 10;

}
.transformBox::after{
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  width: 800px;
  height: 100px;
  background: linear-gradient(0, #F0F5F7 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
}
.classic{
  width: 210px;
  margin-top: 30px;
  .classic-item{
    width:86px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(26, 29, 52, 0.15);
    border-radius: 3px;
  }
  .selected{
    background: $blue;
    color: #FFFFFF;
    transition: 0.8s;
  }
}
.school-list{
  div:nth-child(3n-1){
    margin-left: 20px;
    margin-right: 20px;
  }
}

.school-item{
  width: 386px;
  height: 267px;
  border-radius: 4px;
  &:hover{
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  .school-img{
    width: 386px;
    height: 217px;
    overflow: hidden;
    border-radius: 4px;
    img {
      width: 386px;
      height: 217px;
      transition: 0.5s;
      &:hover{
        transform: scale(1.2);
      }
    }
  }
}
</style>
